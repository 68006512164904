<template>
<div>
    <b-form @submit.prevent="editFeature">
        <b-card class="mb-2">
            <div slot="header">
                <h3>{{ translate('role_features') }}</h3>
            </div>
            <q-item class="full-width p-0">
                <q-item-section>
                    <q-select class="dropdown-choose-role" dense id="chooseRole" :options="roles_options" v-model="selected" @input="getSelectedRoleFeatures(selected)" :label="translate('select_role')" />
                </q-item-section>
                <q-item-section side>
                    <q-btn :disabled="!selected" v-if="!$q.screen.lt.sm" @click.stop.prevent="editFeature()" type="button" no-caps color="success">
                        <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                    </q-btn>
                    <b-btn v-if="$q.screen.lt.sm" :disabled="!selected" variant="success" @click="editFeature()">
                        <q-icon size="1rem" name="check" />
                    </b-btn>
                </q-item-section>
            </q-item>
        </b-card>
        <template v-if="!selected">
            <b-card no-header class="p-0 mb-2 text-muted">
                {{ translate('select_a_role_to_see_and_setup_options_for') }}
            </b-card>
        </template>
        <template v-if="selected">
            <b-card no-body no-header class="p-0 mb-2" v-for="(features,key) in role_features" :key="'role-feature-card-' + key + '-' + (features.id,key.id)">
                <q-expansion-item expand-separator class="full-width q-item-no-padding">
                    <template v-slot:header>
                        <div class="header full-width">
                            <q-item class="full-width m-0 p-0">
                                <q-item-section side>
                                    <q-checkbox :indeterminate-value="null" emit-value :class="key" @input="checkAllForSelectedRoleFeature(features,key)" :id="'feature-checkbox-' + key" v-model="selected_features" :val="key" />
                                </q-item-section>
                                <q-item-section>
                                    <small class="text-muted">{{ getSelectedFeaturesCountPerKey(features) }}/{{ features.length }}</small>
                                    <h5 class="m-0 p-0">{{ translate(key) }}</h5>
                                </q-item-section>
                            </q-item>
                        </div>
                    </template>
                    <q-card class="full-width p-0">
                        <q-separator />
                        <q-card-section class="p-0">
                            <q-item border clickable v-ripple tag="label" class="full-width m-0" v-for="(feature,key_check) in features" :key="'role-feature-key_check-' + key_check + '-' + (feature.id, key_check.id)">
                                <q-item-section side>
                                    <q-checkbox :indeterminate-value="null" emit-value @input="checkAllForSelectedFeatureKey(feature)" :class="feature" :id="'feature_key-checkbox-' + key_check" v-model="selected_feature_keys" :val="feature" />
                                </q-item-section>
                                <q-item-section>
                                    {{ translate(feature) }}
                                </q-item-section>
                            </q-item>
                        </q-card-section>
                    </q-card>
                </q-expansion-item>
            </b-card>
            <q-item class="full-width p-0 mb-2">
                <q-item-section class="pr-0 pl-0" side>
                    <b-btn :disabled="!selected" variant="success" @click="editFeature()">
                        <q-icon size="1rem" name="check" /> {{ translate('save') }}</b-btn>
                </q-item-section>
                <q-space />
                <q-item-section class="pr-0 pl-0" side>
                    <b-btn variant="secondary" @click="resetAll()">
                        <q-icon size="1rem" name="cancel" /> {{ translate('cancel') }}</b-btn>
                </q-item-section>
            </q-item>
        </template>
    </b-form>
</div>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'features',
    data: function () {
        return {
            tickedId: 0,
            showList: false,
            selected: null,
            selected_features: [],
            selected_feature_keys: [],
            role_features: {},
            features: [],
            checked: 'no',
            roles: {},
            roles_options: [],
            page: 1,
        }
    },
    created() {
        this.getRole()
        this.getRoleFeatures()
    },
    methods: {
        getSelectedFeaturesCountPerKey: function (features) {
            return features.filter(feature => { return this.selected_feature_keys.includes(feature) }).length
        },
        resetAll() {
            this.selected = null
            this.selected_features = []
            this.selected_feature_keys = []
        },
        checkAllForSelectedFeatureKey: function () {
            this.setSelectedFeatures()
        },
        checkAllForSelectedRoleFeature: function (features, key) {
            // Get the current content of the selected features.
            this.setSelectedFeatures()

            var selected_key = this.selected_features.includes(key) ? true : false

            if (selected_key) {
                this.selected_features.remove(key)
                for (let [feature_key, feature] of Object.entries(features)) {
                    this.selected_feature_keys.remove(feature)
                }
            } else {
                this.selected_features.push(key)
                for (let [feature_key, feature] of Object.entries(features)) {
                    var in_array = this.allKeysInArray(features, this.selected_feature_keys)
                    if (!in_array) {
                        this.selected_feature_keys.push(feature)
                    }
                }
            }

            return false
        },
        getRole: function () {
            var roleUrl = baseUrl + 'rights/roles?page=' + this.page + '&items_per_page=' + this.$items_per_page
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(roleUrl, {
                    headers: headers
                })
                .then(response => {
                    this.roles = response.data.items
                    for (let [key, role] of Object.entries(response.data.items)) {
                        this.roles_options.push({
                            'label': role,
                            'value': key
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getRoleFeatures: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(baseUrl + 'rights/features', {
                    headers: headers
                })
                .then(response => {
                    this.role_features = response.data.items
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getSelectedRoleFeatures(id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(baseUrl + 'rights/role-features/' + id.value, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items) {
                        this.selected_feature_keys = response.data.items
                        this.setSelectedFeatures()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        setSelectedFeatures: function () {
            var temp_array = []
            for (let [key, features] of Object.entries(this.role_features)) {
                var in_array = this.allKeysInArray(features, this.selected_feature_keys)
                if (in_array) {
                    temp_array.push(key)
                }
            }
            this.selected_features = temp_array
        },
        allKeysInArray: function (array, inner_array) {
            var in_array = true
            for (let [key, feature] of Object.entries(array)) {
                if (!inner_array.includes(feature)) {
                    in_array = false
                    return false
                }
            }
            return in_array
        },
        featureKeyInArray: function (feature, inner_array) {
            var in_array = false
            if (inner_array.includes(feature)) {
                in_array = true
                return false
            }
            return in_array
        },
        editFeature: function () {
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }
            var data = {
                'role_id': parseInt(this.selected.value),
                'features': this.selected_feature_keys
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.put(baseUrl + 'rights/role-features', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var message = 'edit_role_feature_' + this.msg
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            response: data
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.input {
    float: right
}

body .dropdown-choose-role {
    margin-left: -15px;
    margin-right: -15px;

    *[class^="q-"].col.q-field__inner {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

@media screen and (min-width: 768px) {
    .dropdown-choose-role {
        width: calc(100% + 30px);
    }
}

.selected-features-count {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    font-size: 0.75rem;
    font-weight: 700;
}
</style>
